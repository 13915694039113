<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs11 text-left>
        <span class="mainHeader">Purchase</span>
      </v-flex>
      <v-flex xs1 text-right>
        <v-btn block dark color="#3F053C" v-if="GstStat=='GST'" small @click="changeGST2()">
          <!-- <v-icon color="grey">mdi-sync</v-icon> -->GST
        </v-btn>
        <v-btn block dark color="#3F053C" v-if="GstStat=='NOGST'" small @click="changeGST()">
          <!-- <v-icon color="3F053C">mdi-sync</v-icon> -->Non GST
        </v-btn>
        
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
        <v-layout wrap class="subhed2" px-4 pt-4>
            <v-flex xs1 pr-2>
              <span>Bill No</span>
            </v-flex>
            <v-flex xs2 pr-2>
              <span>Date</span>
            </v-flex>
            <v-flex xs2 pr-2>
              <span>Supplier name</span>
            </v-flex>
            <v-flex xs1 pr-2>
              <span>Invoice No.</span>
            </v-flex>
            <v-flex xs2 pr-2>
              <span> Suplier Invoice Date</span>
            </v-flex>
            <v-flex xs2 pr-2>
              <span> Thankam Rate</span>
            </v-flex>
          </v-layout>
          <v-layout wrap class="subhed2" px-4 py-1>
            <v-flex xs1 pr-2>
              <v-text-field
                color="#b234a9"
                label="Bill.No"
                v-model="purchasedata.billNo"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pr-2>
              <v-text-field
                color="#b234a9"
                v-model="purchasedata.create_date"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pr-2 v-if="purchasedata.supplierId">
              <v-text-field
                color="#b234a9" label="date"
                v-model="purchasedata.supplierId.supplierName"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs1 pr-2>
              <v-text-field
                color="#b234a9"
                label="Invoice.No"
                v-model="purchasedata.invoiceNo"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pr-2>
              <v-text-field
                color="#b234a9"
                label="Invoice.No"
                v-model="purchasedata.invoiceDate"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pr-2>
              <v-text-field
                color="#b234a9"
                label="Thankam Rate"
                v-model="thankamRate"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
              
            
            </v-flex> 
            <!-- <v-flex xs2 pr-2>
              <v-select
                :items="CodeList"
                v-model="HSN"
                label="HSN Code"
                item-text="hsnCode"
                item-value="_id"
                outlined
                hide-details
                dense
              ></v-select>
            </v-flex> -->
           
            <v-flex xs2>
              <v-layout wrap>
            
                <v-flex xs2 align-self-center>
                  <v-icon v-if="isLock == true" @click="setLockoff()" >mdi-lock</v-icon>
                  <v-icon v-else @click="setLock()" >mdi-lock-open-outline</v-icon>
                </v-flex>
                <v-flex xs10>
                  <v-btn dark color="#3F053C" @click="checkItem()">
                <v-icon>mdi-plus</v-icon> Item
              </v-btn>
                </v-flex>
              </v-layout>
           
            </v-flex>
          </v-layout>
          <v-layout px-4 py-4>
            <v-flex xs12 v-if="savedList">
              <v-card outlined class="pa-4" v-if="savedList.length > 0">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              <v-checkbox
                                v-model="isSelectAll"
                                hide-details
                                      color="#3F053C"
                                class="mt-0"
                                @click="markAll()"
                              ></v-checkbox>
                            </th>
                            <th class="text-left subhed"><b>S.No.</b></th>
                            <th class="text-left subhed"><b>Category</b></th>
                            <th class="text-left subhed"><b>Item</b></th>
                            <th class="text-left subhed"><b>Design</b></th>
                            <th class="text-left subhed"><b>Count</b></th>
                            <th class="text-left subhed"><b>Net.Wt(gm)</b></th>
                            <!-- <th class="text-left subhed"><b>Gross.Wt </b></th> -->
                            <th class="text-left subhed"><b>Item/Wt(gm)</b></th>
                            <th class="text-left subhed"><b>Cost</b></th>
                            <th class="text-left subhed"><b>StonePrice</b></th>
                            <th class="text-left subhed"><b>Stone.Wt(gm)</b></th>
                            <th class="text-left subhed"><b>Total(Rs.)</b></th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, i) in savedList"
                            :key="i"
                            class="table"
                          >
                          <td>
                              <v-checkbox
                                @click="selectThis(value.checkbox, value._id)"
                                v-model="value.checkbox"
                                      color="#3F053C"
                                hide-details
                              ></v-checkbox>
                            </td>
                            <!-- <td>{{ i + 1 }}</td> -->
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.categoryId.name
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.itemId.name
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.name
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.count"> {{ value.count }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.grossWeight">{{
                                value.grossWeight
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value.itemPerWeight">{{
                                value.itemPerWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.cost">{{ value.cost }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.taxableAmount">{{
                                value.taxableAmount
                              }}</span>
                              <span v-else>-</span>
                            </td>
                           
                            <!-- <td>
                              <v-icon
                                size="18"
                                style="cursor: pointer"
                                @click="(dialoge = true), (curId = value._id)"
                                >mdi-delete-outline</v-icon
                              >
                            </td> -->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout px-4 py-4 v-if="savedList">
            <v-flex xs2>
              <v-layout px-2  py-4 wrap>
                <v-flex xs12>
                  <span class="subhed2">Payment Type</span>
                </v-flex>
                <v-flex xs12
                  > 
                  <v-select
                  solo flat style="border-color: #ced1d6; border-style:solid ; border-width: 2px; border-radius: 6px!important;"
                    hide-details
                    dense
                    v-model="paymentTypes"
                    :items="type"
                    label="Select Type"
                    multiple
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-if="paymentTypes" xs2>
              <v-layout px-2  py-4 wrap>
                <v-flex xs12>
                  <span class="subhed2">Cash</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    color="#b234a9"
                    v-model="cash"
                    solo flat style="border-color: #ced1d6; border-style:solid ; border-width: 2px; border-radius: 6px!important;"
                    @input="cal3()"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-if="paymentTypes" xs2>
              <v-layout px-2  py-4 wrap>
                <v-flex xs12>
                  <span class="subhed2">Thankam (gm)</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    color="#b234a9"
                    v-model="thankam1"
                    solo flat style="border-color: #ced1d6; border-style:solid ; border-width: 2px; border-radius: 6px!important;"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-if="paymentTypes" xs2>
              <v-layout px-2  py-4 wrap>
                <v-flex xs12>
                  <span class="subhed2">Touch Rate (gm)</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    color="#b234a9"
                    v-model="touchrate"
                    solo flat style="border-color: #ced1d6; border-style:solid ; border-width: 2px; border-radius: 6px!important;"
                    @input="cal4()"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-if="paymentTypes" xs2>
              <v-layout px-2  py-4 wrap>
                <v-flex xs12>
                  <span class="subhed2">Gold (gm)</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    color="#b234a9"
                    v-model="gold"
                    solo flat style="border-color: #ced1d6; border-style:solid ; border-width: 2px; border-radius: 6px!important;"
                    
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
           
          </v-layout>
          <v-layout v-if="purchasedata">
            <v-flex xs6 px-4 py-4>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right>
                  Total Weight (gm) :
                </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  ><span v-if="purchasedata.totalNetWeight">{{ purchasedata.totalNetWeight }}</span>
                  <span v-else>-</span></v-flex
                >
              </v-layout>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right>Making Charge (Rs.) : </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  ><span v-if="purchasedata.totalMakingCharge">{{ purchasedata.totalMakingCharge }}</span>
                  <span v-else>-</span></v-flex
                >
              </v-layout>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right
                  >Total Amount to be paid (Rs.) :
                </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  ><span v-if="purchasedata.totalPurchaseCost">{{ purchasedata.totalPurchaseCost }}</span>
                  <span v-else>-</span></v-flex
                >
              </v-layout>
            </v-flex>
            <v-flex xs6 px-4 py-4 align-self-end v-if="savedList.length > 0">
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right>
                  Total Weight (gm) :
                </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  ><span v-if="totalnetweight">{{ totalnetweight }}</span>
                  <span v-else>-</span></v-flex
                >
              </v-layout>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right>Making Charge : </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  ><span v-if="totalmakingcharge">{{ totalmakingcharge }}</span>
                  <span v-else>-</span></v-flex
                >
              </v-layout>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right
                  >Total Amount to be paid :
                </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  ><span v-if="totalAmount">{{ totalAmount }}</span>
                  <span v-else>-</span></v-flex
                >
              </v-layout>
              <v-layout wrap justify-end>
                <v-flex xs5>
                  <v-btn color="#3F053C" dark @click="AddItem()"
                    >Generate Bill
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      billDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      type: ["Cash", "Thankam"],
      isSelectAll: false,
      savedList: [],
      selected: [],
      sflag: true,
      paymentTypes: [],
      cash: 0,
      thankam1: 0,
      touchrate: 0,
      gold: 0,
      gold1 :0,
      purchasedata:{},
      thankamRate: localStorage.getItem("thankamRate"),
      checkvalue: false,
      purchaseId: null,
      totalmakingcharge: null,
      totalnetweight: null,
      totalAmount:null,
      purchaseId2:null,
      isLock:false,
      invoiceDate1:null,
      invoiceDate:null,
      payment: [
        {
          paymentType: null,
          payAmount: null,
        },
      ],
      thankamDetails: [
        {
          thankamId: "63d3a2d42d13b00bbea3a98f",
          boardingRate: null,
          thankamWeight: null,
          batchCode: null,
        },
      ],
      GstStat:"GST",
    };
  },

  mounted() {
    this.purchaseId2 = this.$router.query.id,
    console.log("id==",this.purchaseId2)
    this.getData();
  },
  watch: {
    selected() {
      this.calctotal();
    },
  },
  methods: {
    cal3() {
      this.thankam1 =(this.cash / this.thankamRate)
      this.cal4();
    },
    cal4() {
      this.gold1 =(this.touchrate / 100)
      this.gold =(this.thankam1/this.gold1)
    },
    changeGST(){
      this.GstStat="GST"
      console.log("gststst=",this.GstStat)
    },
    changeGST2(){
      this.GstStat="NOGST"
      console.log("gststst=",this.GstStat)
    },
    markAll() {
      if (this.isSelectAll) {
        this.selected = [];
        this.sflag = false;
        for (let i = 0; i < this.savedList.length; i++) {
          this.savedList[i].checkbox = true;
          this.selected.push(this.savedList[i]._id);
        }
        console.log("markeD", this.selected);
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      } else {
        this.sflag = false;
        for (let i = 0; i < this.savedList.length; i++) {
          this.savedList[i].checkbox = false;
          this.selected = [];
        }
        console.log("Rem", this.selected);
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      }
    },
    selectThis(val, id) {
      this.isSelectAll = false;
      if (val == true) {
        if (this.selected.length > 0) {
          if (!id.includes(this.selected)) {
            this.selected.push(id);
            console.log("ll=");
          }
        } else {
          this.selected.push(id);
        }
      } else {
        var ar = this.selected;
        this.selected = ar.filter((x) => x != id);
       
      }
      
      console.log("Sfull==", this.selected);
    },
    calctotal() {
      this.appLoading = true;
      axios({
        method: "Post",
        url: "/selected/itemcalculation",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          currentpurchaseItemId: this.selected,
          // billNo: this.$route.query.billno,
          purchaseId: this.purchaseno,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
          this.totalmakingcharge=response.data.totalmakingcharge;
          this.totalnetweight=response.data.totalnetweight;
          this.totalAmount=response.data.totalAmount;
            // this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getData() {
      if(this.purchaseId2){
        this.purchaseno= this.purchaseId2
      }
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/item/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          billNo: this.$route.query.billno,
          purchaseId: this.purchaseno,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.savedList = response.data.data;
            this.purchasedata = response.data.purchasedata;
            // this.isLock = response.data.data;
            this.invoiceDate =response.data.purchasedata.invoiceDate;
            this.invoiceDate1 =response.data.purchasedata.invoiceDate;
            this.invoiceDate1=this.formatDate(this.invoiceDate1)
            console.log("invoiceDate1=",this.invoiceDate1)
            this.invoiceDate = this.invoiceDate1
            this.purchaseno = response.data.purchasedata._id;
            this.totalMakingCharge = response.data.totalMakingCharge;
            this.totalnetweight = response.data.totalnetweight;
            this.finalTotal = response.data.finalTotal;
            // this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    AddItem() {
      axios({
        url: "/purchase/payment",
        method: "POST",
        data: {
          paymentType: this.paymentTypes,
          payAmount: this.cash,
          thankam: this.thankam1,
          touchRate: this.touchrate,
          gold: this.gold,
          thankamRate: this.thankamRate,
          purchaseId: this.purchaseno,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;

            this.check = true;
            console.log("check=", this.check);
            this.returndata = response.data;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year
        //  +
        // " , " +
        // hours +
        // ":" +
        // minutes +
        // " " +
        // ampm;

      return strTime;
    },
  },
};
</script>
